.sidebar {
    flex: 1;
    height: 100vh ;
    background-color: rgb(246, 248, 244);
    position: sticky;  

}
.sidebarWrapper {
    padding: 20px 20px 0 20px;
    color: #555;
}
.sidebarImg {
    width: 100%;
    height: 120px;
}
.img {
    margin-top: 30px;
    width: 190px;
}

.sidebarMenu {
    margin-bottom: 14px;
    text-align: left;
}

.sidebarTitle {
    font-size: 15px;
    color: rgb(187, 186, 186);
}
.sidebarList {
    list-style: none;

}
.sidebarListItem {
    padding: 5px;
    cursor: pointer;
    border-radius: 10px ;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.sidebarListItem.active, .sidebarListItem:hover {
    /* background-color: rgb(240, 240, 255); */
    background-color: #00000010;
    color: #555;
}
.sidebarListItem.active {
    background-color: #00000010;

}
.sidebarIcon {
    margin-right: 5px;
    font-size: 20px !important;
}
