.boxModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: auto;
    height: auto;
    box-shadow: 0px 5px 15px rgba(0, 0 , 0, 0.90);
    border-radius: 7px;
    min-width: 510px;
    width: 580px;
    min-height: 500px;
    height: 560px;
    padding: 15px;
}

.navButton {
    width: 100%;
    height: 50px;
    background-color: rgb(246, 248, 244);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonButton {
    height: 35px;    
}
.iconButtonNav {
    font-size: 35px;
}
.titleModal {
    height: 60px;
    padding: 10px 0 0 10px;
    font-size: 30px;
}

.searchbarAdmin {
    outline: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #555;
    border-radius: 5px;
    height: 30px;
    width: 250px;
    padding-left: 5px;
    margin-right: 10px;
}