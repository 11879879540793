.LinkZapas{
    color: #000;
}

.LinkZapas:hover{
    color: #000;
    cursor: pointer;
}

@media (min-width: 1400px) {

    .MoreRatingContainer{
        width: 100%;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingContainer h2{
        font-size: 2.2vw;
        font-weight: bold;
    }
    
    .Zapas{
        width: 60%;
        height: 500px;
        max-height: 500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingCard{
        width: 230px;
        height: 320px;
        border-radius: 10px;
    }
    
    .MoreRatingCardImg{
        width: 100%;
        height: 55%;
        overflow: hidden;
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 18%;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 20px;
        font-weight: bold;
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}


@media (min-width: 1200px) and (max-width: 1399px){

    .MoreRatingContainer{
        width: 100%;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingContainer h2{
        font-size: 2.2vw;
        font-weight: bold;
    }
    
    .Zapas{
        width: 60%;
        height: 500px;
        max-height: 500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingCard{
        width: 230px;
        height: 320px;
        border-radius: 10px;
    }
    
    .MoreRatingCardImg{
        width: 100%;
        height: 55%;
        overflow: hidden;
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 18%;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 20px;
        font-weight: bold;
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

}

@media (min-width: 992px) and (max-width: 1199px){

    .MoreRatingContainer{
        width: 100%;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingContainer h2{
        font-size: 2.2vw;
        font-weight: bold;
    }
    
    .Zapas{
        width: 60%;
        height: 500px;
        max-height: 500px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    .MoreRatingCard{
        width: 230px;
        height: 320px;
        border-radius: 10px;
    }
    
    .MoreRatingCardImg{
        width: 100%;
        height: 55%;
        overflow: hidden;
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 18%;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 20px;
        font-weight: bold;
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

}

@media (min-width: 768px) and (max-width: 991px){

    .MoreRatingContainer{
        width: 100%;
        min-height: 400px;
        max-height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingContainer h2{
        font-size: 32px;
        font-weight: bold;
    }
    
    .Zapas{
        width: 80%;
        height: 300px;
        max-height: 450px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCard{
        width: 150px;
        min-height: 220px;
        max-height: 250px;
        border-radius: 10px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg{
        width: 100%;
        max-height: 120px;
        overflow: hidden;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 20px;
        font-weight: bold;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }

}

@media (min-width: 576px) and (max-width: 767px){

    .MoreRatingContainer{
        width: 100%;
        min-height: 300px;
        max-height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingContainer h2{
        font-size: 28px;
        font-weight: bold;
    }
    
    .Zapas{
        width: 80%;
        height: 300px;
        max-height: 450px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCard{
        width: 150px;
        min-height: 220px;
        max-height: 250px;
        border-radius: 10px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg{
        width: 100%;
        max-height: 120px;
        overflow: hidden;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 40px;
        font-size: 10px;
        font-weight: bold;
        line-height: 15px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 14px;
        font-weight: bold;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }

}

@media (max-width: 575px){

    .MoreRatingContainer{
        width: 100%;
        min-height: 450px;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingContainer h2{
        font-size: 28px;
        font-weight: bold;
    }

    .Zapas{
        width: 80%;
        height: 400px;
        max-height: 450px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCard{
        width: 120px;
        min-height: 180px;
        max-height: 200px;
        border-radius: 10px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg{
        width: 100%;
        max-height: 90px;
        overflow: hidden;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardImg img{
        width: 80%;
        position: relative;
        bottom: 10px;
    }
    
    .MoreRatingCardName{
        width: 100%;
        height: 40px;
        font-size: 10px;
        font-weight: bold;
        line-height: 15px;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardPrice{
        width: 100%;
        height: 12%;
        font-size: 14px;
        font-weight: bold;
        /* border: thin dotted #f00; */
    }
    
    .MoreRatingCardRate{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }




}