.boxModalPromocion{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: auto;
    height: auto;
    box-shadow: 0px 5px 15px rgba(0, 0 , 0, 0.90);
    border-radius: 7px;
    width: 260px;
    height: 300px;
}
.titlePromocion {
        font-size: 22px;
        color: #555;
        display: flex;
        justify-content: center;
}
.boxInputPromocion {
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
}
.blockBtnPromocion {
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 35px;
}
.btnPromocion {
    border: none;
    outline: none;
    height: 35px;
    width: 150px;
    border-radius: 5px;
}
.estadoPromocion {
    margin-left: 5px;
    margin-top: 3px;
}