.BrandImageBalenciaga{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/balenciaga-campaign.jpg') }

.BrandImageGucci{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/gucci-campaign.jpg') }

.BrandImageAdidas{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/adidas-campaign.jpg') }

.BrandImageNike{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/nike-campaign.jpg') }

.BrandImageOffWhite{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/off-white-campaign.jpg') }

.BrandImagePuma{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/puma-campaign.jpg') }

.BrandImageReebok{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/reebok-campaign.jpg') }

.BrandImageVans{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/vans-campaign.jpg') }

.BrandImageUnder{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/under-campaign.jpg') }

.BrandImageJordan{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/jordan-campaign.jpg') }

.CategoryImageSport{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/deportivaPortada.jpg') }

.CategoryImageUrban{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/urbanPortada.jpg') }

.CategoryImageFlipFlop{ background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/flipFlopPortada.jpg') }

.BrandImageGucci h3, 
.BrandImageAdidas h3, 
.BrandImageNike h3, 
.BrandImageOffWhite h3, 
.BrandImagePuma h3, 
.BrandImageReebok h3, 
.BrandImageVans h3,
.BrandImageUnder h3,
.BrandImageJordan h3,
.CategoryImageSport h3,
.CategoryImageUrban h3,
.CategoryImageFlipFlop h3{
    margin-top: 50px;
    font-size: 7vw;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3vw;
    text-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}

.BrandImageUnder h3, .CategoryImageSport h3, .CategoryImageUrban h3, .CategoryImageFlipFlop h3{
    letter-spacing: 2vw;
    font-size: 5vw;
}

.BrandProducts{
    width: 75%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 30px;
    /* border: thin dotted #f00; */
}

.BrandProductsCards{
    width: 235px;
    height: 350px;
    /* border: thin dotted #f00; */
}

@media (min-width: 1400px) {

    .BrandContainer{
        width: 100%;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 400px;
        max-height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

}

@media (min-width: 1200px) and (max-width: 1399px){

    .BrandContainer{
        width: 100%;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    } 

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 250px;
        max-height: 250px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

}


@media (min-width: 992px) and (max-width: 1199px){

    .BrandContainer{
        width: 100%;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }   
    
    .BrandProducts{
        width: 75%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 30px;
        /* border: thin dotted #f00; */
    }

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 400px;
        max-height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
}


@media (min-width: 768px) and (max-width: 991px){

    .BrandContainer{
        width: 100%;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }   
    
    .BrandProducts{
        width: 70%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 30px;
        /* border: thin dotted #f00; */
    }

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 250px;
        max-height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .BrandImageGucci h3, 
    .BrandImageAdidas h3, 
    .BrandImageNike h3, 
    .BrandImageOffWhite h3, 
    .BrandImagePuma h3, 
    .BrandImageReebok h3, 
    .BrandImageVans h3,
    .BrandImageUnder h3,
    .BrandImageJordan h3,
    .CategoryImageSport h3,
    .CategoryImageUrban h3,
    .CategoryImageFlipFlop h3{
        margin-top: -20px;
        font-size: 12vw;
        color: white;
        text-transform: uppercase;
        letter-spacing: 3vw;
        text-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    }
    
}


@media (min-width: 576px) and (max-width: 767px){

    .BrandContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }   
    
    .BrandProducts{
        width: 69%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 30px;
    }

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 250px;
        max-height: 250px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .BrandImageGucci h3, 
    .BrandImageAdidas h3, 
    .BrandImageNike h3, 
    .BrandImageOffWhite h3, 
    .BrandImagePuma h3, 
    .BrandImageReebok h3, 
    .BrandImageVans h3,
    .BrandImageUnder h3,
    .BrandImageJordan h3,
    .CategoryImageSport h3,
    .CategoryImageUrban h3,
    .CategoryImageFlipFlop h3{
        margin-top: -20px;
        font-size: 12vw;
        color: white;
        text-transform: uppercase;
        letter-spacing: 3vw;
        text-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    }
    


}
@media (max-width: 575px){

    .BrandContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }   
    
    .BrandProducts{
        width: 75%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 30px;
    }

    .BrandImageBalenciaga, 
    .BrandImageGucci, 
    .BrandImageAdidas, 
    .BrandImageNike, 
    .BrandImageOffWhite, 
    .BrandImagePuma, 
    .BrandImageReebok, 
    .BrandImageVans, 
    .BrandImageUnder, 
    .BrandImageJordan,
    .CategoryImageSport,
    .CategoryImageUrban,
    .CategoryImageFlipFlop{
        width: 100%;
        height: 200px;
        max-height: 200px;
        margin-top: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .BrandImageGucci h3, 
    .BrandImageAdidas h3, 
    .BrandImageNike h3, 
    .BrandImageOffWhite h3, 
    .BrandImagePuma h3, 
    .BrandImageReebok h3, 
    .BrandImageVans h3,
    .BrandImageUnder h3,
    .BrandImageJordan h3,
    .CategoryImageSport h3,
    .CategoryImageUrban h3,
    .CategoryImageFlipFlop h3{
        margin-top: -20px;
        font-size: 12vw;
        color: white;
        text-transform: uppercase;
        letter-spacing: 3vw;
        text-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    }
    
}