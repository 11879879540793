.CatologueContainer{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
}

.FormRadioCatalogue{
    margin: 0 0 20px 0;
}

.RadioFilterLabel{
    margin: 0 0 0 50px;
}

@media (min-width: 1601px) {

    .Portada{
        width: 100%;
        max-height: 400px;
        height: 40vh;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    .Info{ 
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 60px;
    }

    .Filtros{
        width: 15%;
        height: auto;
        padding-bottom: 50px;
    }
    
    .TitleFilter{
        width: 100%;
        height: 10px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .SelectFilter{ 
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 80%;
        height: 30px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 1em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .Productos{
        width: 80%; 
        min-height:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-right: 25px;
        margin-bottom: 30px;
    }
    
    .Paginado{
        width: 40%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 16px;
        height: 16px;
        position: relative;
        top: 1px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .RadioFilter:hover{
        cursor: pointer;
    }

    .RadioFilter:checked {
        border: 8px solid rgb(202, 0, 67);
    }

    .BtnCatalogueDelete{
        width: 120px;
        height: 30px;
        font-size: 16px;
        margin-bottom: 25px;
    }

}

@media (min-width: 1400px) and (max-width: 1600px){

    .Portada{
        width: 100%;
        max-height: 400px;
        height: 40vh;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    .Info{ 
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 60px;
    }
    
    .Filtros{
        width: 15%;
        height: auto;
        padding-bottom: 50px;
    }
    
    .TitleFilter{
        width: 100%;
        height: 10px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .SelectFilter{ 
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 80%;
        height: 30px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 1em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .Productos{
        width: 75%; 
        min-height:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-right: 15px;
        margin-bottom: 30px;
    }
    
    .Paginado{
        width: 40%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 16px;
        height: 16px;
        position: relative;
        top: 3px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
        
    }

    .RadioFilter:hover{
        cursor: pointer;
    }

    .RadioFilter:checked {
        border: 8px solid rgb(202, 0, 67);
    }

    .BtnCatalogueDelete{
        width: 120px;
        height: 30px;
        font-size: 16px;
        margin-bottom: 25px;
    }
    
}

@media (min-width: 1200px) and (max-width: 1399px){

    .Portada{
        width: 100%;
        max-height: 350px;
        height: 300px;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }

    .Info{ 
        width: 80%;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
    }
    
    .Filtros{
        width: 20%;
        height: auto;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Filtros h1{
        font-size: 1.5em;
        font-weight: bold;
        padding-bottom: 10px;
    }
    
    .TitleFilter{
        width: 100%;
        height: 10px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .SelectFilter{ 
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 80%;
        height: 25px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 0.8em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .RadioFilterLabel{
        margin: 0 0 0 15px;
    }
    
    .Productos{
        width: 95%; 
        min-height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-left: 5px;
        margin-bottom: 30px;
    }
    
    .Paginado{
        width: 41%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 16px;
        height: 16px;
        position: relative;
        top: 1px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .BtnCatalogueDelete{
        width: 100px;
        height: 25px;
        font-size: 14px;
        margin-bottom: 25px;
    }
}

@media (min-width: 992px) and (max-width: 1199px){

    .Portada{
        width: 100%;
        max-height: 300px;
        height: 300px;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        top: -40px;
    }

    .Info{ 
        width: 95%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        top: -50px;
    }

    .Filtros{
        width: 100%;
        height: 50px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;    
        justify-content: space-around;
    }
    
    .Filtros h1{
        display: none;
    }
    
    .TitleFilter{
        width: 120px;
        height: 20px;
        float: left;
        font-size: 1.1vw;
        font-weight: 500;
        margin-top: -22px;
    }
    
    .SelectFilter{ 
        width: 100px;
        height: 30px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 90px;
        height: 20px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 0.8em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .FormRadioCatalogue{
        width: 140px;
        margin-bottom: 30px;
    }   

    .LabelCatalogueOrder{
        font-size: 0.8rem;
    }
    
    .RadioFilterLabel{
        font-size: 0.8rem;
        margin: 0 0 0 15px;
    }

    .Productos{
        width: 75%; 
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    
    .Paginado{
        width: 60%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 12px;
        height: 12px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .BtnCatalogueDelete{
        width: 100px;
        height: 25px;
        font-size: 14px;
        margin-bottom: 25px;
    }

    .BtnListFilterDelete{
        display: none;
    }
    
}

@media (min-width: 768px) and (max-width: 991px){

    .Portada{
        width: 100%;
        max-height: 250px;
        height: 250px;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        top: -20px;
    }

    .Info{ 
        width: 95%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        top: -50px;
    }

    .Filtros{
        width: 100%;
        height: 100px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;    
        margin-top: 30px;
        justify-content: space-around;
    }
    
    .Filtros h1{
        display: none;
    }
    
    .TitleFilter{
        width: 120px;
        height: 20px;
        float: left;
        font-size: 1.6vw;
        font-weight: 500;
        margin-top: -22px;
    }
    
    .SelectFilter{ 
        width: 100px;
        height: 30px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 90px;
        height: 20px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 0.8em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .FormRadioCatalogue{
        width: 140px;
        margin-bottom: 30px;
    }   

    .LabelCatalogueOrder{
        font-size: 1rem;
    }
    
    .RadioFilterLabel{
        font-size: 1rem;
        margin: 0 0 0 15px;
    }

    .Productos{
        width: 90%; 
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-left: 10px;
        margin-bottom: 20px;
    }
    
    .Paginado{
        width: 60%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 15px;
        height: 15px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .BtnCatalogueDelete{
        width: 100px;
        height: 25px;
        font-size: 14px;
        margin-bottom: 25px;
    }

    .BtnListFilterDelete{
        display: none;
    }

}

@media (min-width: 576px) and (max-width: 767px){

    .Portada{
        width: 100%;
        max-height: 250px;
        height: 250px;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        top: -20px;
    }

    .Info{ 
        width: 95%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        top: -50px;
    }

    .Filtros{
        width: 90%;
        height: 100px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;    
        margin-top: 30px;
        justify-content: space-around;
    }
    
    .Filtros h1{
        display: none;
    }
    
    .TitleFilter{
        width: 120px;
        height: 20px;
        float: left;
        font-size: 2vw;
        font-weight: 500;
        margin-top: -22px;
    }
    
    .SelectFilter{ 
        width: 100px;
        height: 30px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 90px;
        height: 20px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 0.8em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .FormRadioCatalogue{
        width: 140px;
        margin-bottom: 30px;
    }   

    .LabelCatalogueOrder{
        font-size: 0.9rem;
    }
    
    .RadioFilterLabel{
        font-size: 0.9rem;
        margin: 0 0 0 15px;
    }

    .Productos{
        width: 90%; 
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-left: 10px;
        margin-bottom: 20px;
    }
    
    .Paginado{
        width: 70%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 13px;
        height: 13px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .BtnCatalogueDelete{
        width: 90px;
        height: 20px;
        font-size: 12px;
        margin-bottom: 30px;
    }

    .BtnListFilterDelete{
        display: none;
    }

}

@media (max-width: 575px){

    .Portada{
        width: 100%;
        max-height: 170px;
        height: 150px;
        background-color: #ccccce;
        background-image: url(../../assets/portada.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 120%;
        overflow: hidden;
        position: relative;
        top: 5px;
    }

    .Info{ 
        width: 95%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        top: -50px;
    }

    .Filtros{
        width: 90%;
        height: 100px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;    
        margin-top: 50px;
        justify-content: space-evenly;
    }
    
    .Filtros h1{
        display: none;
    }
    
    .TitleFilter{   
        display: none;
    }
    
    .SelectFilter{ 
        width: 100px;
        height: 30px;
        margin-bottom: 20px;
    }
    
    .Select{
        width: 90px;
        height: 20px;
        outline: 0; 
        border-radius: 12px;
        padding: 0px 10px 0 10px;
        font-size: 0.7em;
        transition: all 0.25s ease; 
        color: rgb(99, 99, 99);
        border: solid 1px #ccc;
    }
    
    .Select:hover{
        box-shadow: 0 5px 10px #00000030;
        cursor: pointer;
        border: none;
    }
    
    .FormRadioCatalogue{
        width: 140px;
        margin-bottom: 30px;
    }   

    .LabelCatalogueOrder{
        font-size: 0.8rem;
    }
    
    .RadioFilterLabel{
        font-size: 0.8rem;
        margin: 0 0 0 15px;
    }

    .Productos{
        width: 90%; 
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .Shoes{
        position: relative;
        float: left;
        margin-bottom: 20px;
    }
    
    .Paginado{
        width: 70%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .RadioFilter{
        margin: 0 5px;
        width: 12px;
        height: 12px;
        border: solid 2px #999;
        margin-right: 5px;   
        transition: 0.2s all linear;
    }

    .BtnCatalogueDelete{
        width: 80px;
        height: 18px;
        font-size: 10px;
        margin-bottom: 30px;
    }

    .BtnListFilterDelete{
        display: none;
    }

}