.boxModalBrands {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 500;
    height: 500;
    box-shadow: 0px 5px 15px rgba(0, 0 , 0, 0.90);
    padding: 5px;
    border-radius: 5px;
}

.buttonResetAdmin {
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 20px;
    background-color: transparent;
    color: #555;
}