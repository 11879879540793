.UsContainer{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    padding: 50px;
}

.Style{
    width: 50vw;
    max-height: 80vh;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.UsContainer h1{
    font-size: 2vw;
    font-weight: bold;
}

.UsContainer p{
    font-size: 1.3vw;
}

.TextModal p{
    text-align: center;
    font-size: 1.2vw;
}

.PortadaModal{
    width: 100%;
    height: 250px;
    margin: 20px 0;
    background-image: url(../../assets/adidas-pureboost-dpr.jpg);
    background-size: cover;
    background-position: center center;
    background-color:black;
}

.ButtonModal{
    width: 25%;
    min-height: 4vh;
    font-size: 2.5vw;
    background: black;
    color: white; 
    border-radius: 10px;
    margin-top: 25px;
    position: relative;
    top: -1vh;
}

@media (min-width: 992px) and (max-width: 1399px){

    .Style{
        width: 50vw;
        max-height: 80vh;
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }

    .UsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        padding: 20px;
    }
    
    .UsContainer h1{
        font-size: 36px;
        font-weight: bold;
    }
    
    .UsContainer p{
        width: 80%;
        font-size: 20px;
    }
    
    .TextModal p{
        text-align: center;
        font-size: 1rem;
    }

    .ButtonModal{
        min-width: 25%;
        min-height: 25px;
        font-size: 16px;
        background: black;
        color: white; 
        border-radius: 10px;
        margin-top: 25px;
        position: relative;
        top: -1vh;
    }
}

@media (min-width: 768px) and (max-width: 991px){

    .Style{
        width: 70vw;
        max-height: 80vh;
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }

    .UsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0 30px 0;
        padding: 20px;
    }
    
    .UsContainer h1{
        font-size: 36px;
        font-weight: bold;
    }
    
    .UsContainer p{
        width: 70%;
        font-size: 20px;
    }
    
    .TextModal p{
        text-align: center; 
        font-size: 16px;
    }

    .ButtonModal{
        width: 25%;
        min-height: 25px;
        font-size: 16px;
        background: black;
        color: white; 
        border-radius: 10px;
        margin-top: 35px;
        position: relative;
    }
}

@media (min-width: 576px) and (max-width: 767px){

    .Style{
        width: 70vw;
        max-height: 75vh;
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }

    .UsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 20px;
    }

    .UsContainer h1{
        font-size: 28px;
        font-weight: bold;
    }
    
    .UsContainer p{
        width: 70%;
        font-size: 16px;
    }

    .PortadaModal{
        width: 100%;
        min-height: 130px;
        position: relative;
        margin-top: 10px;
        /* background-image: url(../../assets/adidas-pureboost-dpr.jpg); */
        background-size: cover;
        background-position: center center;
        background-color:black;
    }

    .TextModal p{
        font-size: 1rem;
        line-height: 22px;
        text-align: center;
        position: relative;
        top: -17px;
        margin-bottom: 2vh;
    }

    .TitleModal h1{
        font-size: 2rem;
    }

    .ButtonModal{
        width: 150px;
        min-height: 25px;
        font-size: 2.5vw;
        background: black;
        color: white; 
        border-radius: 10px;
        margin-top: 25px;
        position: relative;
        top: -2vh;
    }

}

@media (max-width: 575px){

    .UsContainer{
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
    }

    .Style{
        width: 80vw;
        max-height: 500px;
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }

    .PortadaModal{
        width: 100%;
        min-height: 50px;
        height: 130px;
        position: relative;
        margin-top: 5px;
        /* background-image: url(../../assets/adidas-pureboost-dpr.jpg); */
        background-size: cover;
        background-position: center center;
        background-color:black;
    }
    
    .TextModal p{
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        position: relative;
        top: -20px;
        margin-bottom: 10px;
    }

    .TitleModal h1{
        font-size: 28px;
    }
    
    .UsContainer h1{
        font-size: 28px;
        font-weight: bold;
    }
    
    .UsContainer p{
        width: 85%;
        font-size: 16px;
    }

    .BtnUsModal{
        width: 100px;
        height: 30px;
    }

    .ButtonModal{
        width: 120px;
        height: 30px;
    }

}