.FooterContainer{
    width: 100vw;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0 10px 0;
}

@media (min-width: 1400px){
    
    .FootText{
        font-size: 0.7vw;
    }
}

@media (min-width: 576px) and (max-width: 1399px){
   
    .FootText{
        font-size: 1vw;
    }
}

@media (max-width: 575px){

    .FootText{
        font-size: 2vw;
    }
}