.ContainerBrand{
    width: 100vw;
    height: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #000;
}

@media (min-width: 1200px) and (max-width: 1399px){
    .ContainerBrand{
        height: 500px;
        background: #000;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .ContainerBrand{
        height: 450px;
        background: #000;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .ContainerBrand{
        height: 400px;
        background: #000;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .ContainerBrand{
        height: 100%;
        background: #000;
    }
}

@media (max-width: 575px){
    .ContainerBrand{
        height: 100%;
        background: #000;
    }
}