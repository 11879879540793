.boxModalCategories{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: auto;
    height: auto;
    box-shadow: 0px 5px 15px rgba(0, 0 , 0, 0.90);
    border-radius: 5px;
    padding: 5px;
    
}
