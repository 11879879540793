.recovery-content{
    margin: 0 auto;
    border:thin solid #ccc;
    height: 500px;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 650px;

}
.recoverty-data{
    margin: 0 auto;
    border: thin solid rgb(194, 194, 194);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 350px;    
    height: 250px;
    margin-top: 100px;
}
.label-recovery{
    width: 145px;
    padding-right: 15px;
    margin-top: 10px;
    text-align: start;
}
.button-recovery{
    margin-top: 15px;
    border: thin solid #ccc;
    width: 75px;
    border-radius: 5px;
}
.button-recovery:hover{
    animation-name: fadeIn;
    animation-duration: 1s;
    background-color: #fc4444;
}
.field-recovery{
    border-radius: 5px;
    border: thin solid #ccc;
    outline:none;
}
.error-recovery{
    color: red;
}