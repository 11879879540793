.log-Cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/nikeBotaRoja.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.forgot-msg{
    font-size: 14px;
    margin-bottom: 5px;
    color:red;
}

.log-Box{
    border: solid #ccc 1px;
    width: 900px;
    height: 600px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    top: 10vh;
    padding: 15px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.border-dec{
    border: solid #ccc 1px;
    width: 480px;
}

.log-Box h1{
    text-align: left;
    margin-left: 23px;
}

.data-Field{
    width: 350px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    outline: none;
}

.form-box-log{
    margin-top: 35px;
    margin-left: 55px;
    width: fit-content;
}

.img-log{
    position: absolute;
    top: 0;
    left: 500px;
    width: 400px;
    height: 600px;
    background-image: url('../../assets/sneake-new.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.data-Field p{
    font-size: 16px;
}

.data-Field a {
    text-decoration: none;
    color: #fc4444;
}

.forgot-log{
    color: #fc4444;
    cursor: pointer;
}

.Field{
    width: 300px;
    height: 35px;
    border-radius: 5px;
    padding-left: 10px;
    border: thin solid #ccc;
    outline: none;
}

.btn-sub{
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 5px;
    background:none;
    border: thin solid #ccc;
    outline: none;
}

.btn-sub:hover{
    background-color: #fc4444; 
    transition-duration: 1s;
    font-size: 15px;
    font-weight: 100;
    transform:scale(1.1);
}

.btn-sub:after{
    transition: all 0.3s ease-in-out;
    transition-duration: 1s;
}

.log-error{
    font-size: 18px;
    color: red;
}

.log-error-inp{
    font-size: 12px;
    color: red;
}

@media (min-width: 1200px) and (max-width: 1399px){
    
    .log-Box{
        width: 65%;
        min-height: 60%;
        max-height: 65%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border: solid #ccc 1px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
        overflow: hidden;
    }
    
    .log-Box h1{
        font-size: 40px;
        width:100%;
        text-align: left;
    }
    
    .img-log{
        position: absolute;
        top: 0;
        left: 35vw;
        width: 30vw;
        height: 100%;
        background-image: url('../../assets/sneake-new.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    
    .border-dec{
        width: 51%;
        margin-right: 30vw;
        border: solid #ccc 1px;
    }
    
    .form-box-log{
        width: 45%;
        margin-top: 15px;
        margin-right: 34vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 55px;
        width: fit-content;
    }

    .Field{
        font-size: 14px;
    }
    
}

@media (min-width: 992px) and (max-width: 1199px){

    .log-Box{
        width: 75%;
        min-height: 90%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border: solid #ccc 1px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
        overflow: hidden;
    }

    .log-Box h1{
        width:100%;
        text-align: left;
    }
    
    .img-log{
        /* border: solid blue 1px; */
        position: absolute;
        top: 0;
        left: 40vw;
        width: 35vw;
        height: 100%;
        background-image: url('../../assets/sneake-new.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .border-dec{
        width: 51%;
        margin-right: 35vw;
        border: solid #ccc 1px;
    }
    
    .form-box-log{
        width: 45%;
        margin-top: 15px;
        margin-right: 40vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 55px;
        width: fit-content;
    }

    .Field{
        font-size: 16px;
    }
    
   
}

@media (min-width: 768px) and (max-width: 991px){

    .log-Box{
        width: 90%;
        height: 60%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border: solid #ccc 1px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
    }
    
    .log-Box h1{
        width: 88vw;
        text-align: left;
    }
    
    .img-log{
        /* border: solid blue 1px; */
        position: absolute;
        top: 0;
        left: 50vw;
        width: 40vw;
        height: 100%;
        background-image: url('../../assets/sneake-new.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover
    }
    
    .border-dec{
        width: 51%;
        margin-right: 40vw;
        border: solid #ccc 1px;
    }
    
    .form-box-log{
        width: 45%;
        margin-top: 15px;
        margin-right: 47vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 55px;
        width: fit-content;
        /* border: solid blue 1px; */
    }

    .Field{
        font-size: 16px;
    }
    
}

@media (min-width: 576px) and (max-width: 767px){

    .log-Box{
        width: 70vw;
        height: 60vh;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border: solid #ccc 1px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
    }

    .log-Box h1{
        width: 65vw;
        text-align: left;
    }

    .img-log{
        display: none;
    }

    .border-dec{
        width: 65vw;
        border: solid #ccc 1px;
    }
    
    .form-box-log{
        width: 60vw;
        margin-top: 15px;
        margin-right: 8vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 55px;
        /* width: fit-content; */
    }
    
}

@media (max-width: 575px){

    .log-Box{
        width: 80%;
        min-height: 60%;
        height: 65%;
        max-height: 65%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #fff;
        border: solid #ccc 1px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
    }
    
    .log-Box h1{
        width: 100%;
        font-size: 28px;
        text-align: left;
    }
    
    .img-log{
        display: none;
    }
    
    .border-dec{
        width: 65vw;
        border: solid #ccc 1px;
    }
    
    .form-box-log{
        width: 70vw;
        margin-top: 15px;
        margin-right: 13vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 55px;
        /* width: fit-content; */
    }
    
    .Field{
        width: 90%;
        height: 30px;
        border-radius: 5px;
        padding-left: 10px;
        border: thin solid #ccc;
        outline: none;
        font-size: 14px;
    }
    
    .data-Field{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        outline: none;
        /* border: thin solid #f00; */
    }

    .data-Field p{
        width: 80%;
        font-size: 14px;
    }

    .data-Field a {
        text-decoration: none;
        color: #fc4444;
    }

    .btn-sub{
        width: 90px;
        height: 35px;
        border-radius: 5px;
        background:none;
        border: thin solid #ccc;
        outline: none;
    }

    

}