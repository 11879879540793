.PayContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/screen-1.jpg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

