
.formOfertas {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
}
.boxOfertas {
    width: 300px;
    height: 400px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
}
.titleOfertas {
font-size: 25px;
color: #555;
}
.titleOfertasNav {
    font-size: 25px;
    color: #555;
    padding-right: 25px;
}
.blockOfertas {
    height: 80px;
    text-align: left;
    margin-left: 15px;

}
.selectOfertas {
    height: 35px;
    width: 170px;
    border-radius: 3px ;
    border: 1px solid  #555;
}
.titleInputOfertas {
    margin: 0px;
    padding: 0px;
}
.inputOfertas {
    outline: none;
    height: 35px;
    width: 170px;
    border: 1px solid  #555;
    border-radius: 3px ;

}
.blockOfertasRadio {
    height: 50px;
    margin-left: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.blockOfertasCheck {
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 15px;

}

.selectOfertasCheck {
    height: 35px;
    width: 170px;
    border-radius: 3px ;
    border: 1px solid  #555;
    outline: none;

}

.blockBtnOfertas {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
.btnOfertas {
    border: none;
    outline: none;
    height: 35px;
    width: 150px;
    border-radius: 4px;
    text-transform: capitalize;
    
}