.ShoppingCartContainer{
    width: 50%;
    max-width: 50%;
    height: auto;
    margin: 0 auto;
    /* border: thin dotted #f00;     */
}

.ShoppingCartNav{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 1)); */
    /* border: thin dotted #f00; */
}

.ShoppingCartLogo{
    width: 20%;
    /* border: thin dotted #f00; */
}

.ShoppingCartLogo img{
    width: 80%;
    /* border: thin dotted #f00; */
}

.ShoppingCartTitle{
    width: 40%;
    min-width: 21%;
    text-align: left;
    /* border: thin dotted #f00; */
}

.BtnShoppingCart{
    width: 40%;
    /* border: thin dotted #f00; */
}

.CartItemDelete{
    width: 120px;
    height: 30px;
    border: none;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    background-color: rgb(49, 102, 202);
}

.CartBack{
    width: 100px;
    height: 30px;
    border: none;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    background-color: rgb(49, 102, 202);
}

.CartItemDelete:hover, .ContinuarBtnShoppingCart:hover, .CartBack:hover{
    cursor: pointer;
    background-color: rgb(27, 73, 158);
    box-shadow: 0 5px 10px #00000030;
}

.TotalShoppingCart{
    width: 25%;
    float: right;
}

.TotalShoppingCart p{ 
    padding: 5px 20px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 30px; 
    font-weight: bold;
}

.ContinuarBtnShoppingCart{
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    background-color: rgb(49, 102, 202);
}

@media (min-width: 1400px){

    .TotalShoppingCart{
        width: 25%;
        margin-top: 20px;
    }

    .TotalShoppingCart p{ 
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #000;
        color: #fff;
        font-size: 1.2vw; 
        font-weight: bold;
    }

    .ContinuarBtnShoppingCart{
        width: 100px;
        height: 22px;
        border: none;
        margin: 0 5px;
        border-radius: 10px;
        font-size: 0.8vw;
        color: #fff;
        background-color: rgb(49, 102, 202);
    }

    .ContinuarBtnShoppingCart:hover{
        color:#fff;
    }
    
}

@media (min-width: 1200px) and (max-width: 1399px){

    .ShoppingCartNav{
        width: 100%;
        height: 50px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 1)); */
        /* border: thin dotted #f00; */
    }

    .ShoppingCartTitle h1{
        width: 65%;
        min-width: 21%;
        font-size: 2vw;
        text-align: left;
        /* border: t    hin dotted #f00; */
    }

    .CartItemDelete{
        width: 100px;
        height: 22px;
        border: none;
        margin: 0 5px;
        border-radius: 5px;
        font-size: 12px;
        color: #fff;
        background-color: rgb(49, 102, 202);
    }
    
    .CartBack{
        width: 90px;
        height: 22px;
        border: none;
        margin: 0 5px;
        border-radius: 5px;
        font-size: 12px;
        color: #fff;
        background-color: rgb(49, 102, 202);
    }

    .TotalShoppingCart{
        width: 25%;
        margin-top: 20px;
    }
    
    .TotalShoppingCart p{ 
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #000;
        color: #fff;
        font-size: 1.5vw; 
        font-weight: bold;
    }

    .ContinuarBtnShoppingCart{
        width: 100px;
        height: 22px;
        border: none;
        margin: 0 5px;
        border-radius: 10px;
        font-size: 11px;
        color: #fff;
        background-color: rgb(49, 102, 202);
    }

    .ContinuarBtnShoppingCart:hover{
        color:#fff;
    }

}
