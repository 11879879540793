.PaginadoContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
}

.Page{
    width: 30px;
    height: 30px;
    text-align: center;
    float: left;
    margin: 0 5px;
}

.PageCurrent{
    color: #f00;
    border-bottom: solid 2px #f00;
}

.Number{
    padding: 5px;
    color: #000;
    font-weight: bold;
}

@media (min-width: 1400px){

    .Page{
        width: 20px;
        height: 25px;
        text-align: center;
        float: left;
        margin: 0 5px;
    }
    
    .Number{
        font-size: 17px;
        padding: 5px;
        color: #000;
        font-weight: bold;
    }
    
    .PageCurrent{
        font-size: 17px;
        color: #f00;
        font-weight: bold;
        border-bottom: solid 2px #f00;
    }

}


@media (min-width: 1200px) and (max-width: 1399px){

    .Page{
        width: 20px;
        height: 25px;
        text-align: center;
        float: left;
        margin: 0 5px;
    }
    
    .Number{
        font-size: 16px;
        padding: 5px;
        color: #000;
        font-weight: bold;
    }
    
    .PageCurrent{
        font-size: 16px;
        color: #f00;
        font-weight: bold;
        border-bottom: solid 2px #f00;
    }

}


@media (min-width: 992px) and (max-width: 1199px){

    .Page{
        width: 20px;
        height: 25px;
        text-align: center;
        float: left;
        margin: 0 5px;
    }
    
    .Number{
        font-size: 14px;
        padding: 5px;
        color: #000;
        font-weight: bold;
    }
    
    .PageCurrent{
        font-size: 14px;
        color: #f00;
        font-weight: bold;
        border-bottom: solid 2px #f00;
    }

}

@media (min-width: 768px) and (max-width: 991px){

    .Page{
        width: 20px;
        height: 25px;
        text-align: center;
        float: left;
        margin: 0 5px;
    }
    
    .Number{
        font-size: 14px;
        padding: 5px;
        color: #000;
        font-weight: bold;
    }
    
    .PageCurrent{
        font-size: 14px;
        color: #f00;
        font-weight: bold;
        border-bottom: solid 2px #f00;
    }

}

@media (min-width: 576px) and (max-width: 767px){

    .Page{
        width: 20px;
        height: 25px;
        text-align: center;
        float: left;
        margin: 0 5px;
    }
    
    .Number{
        font-size: 14px;
        padding: 5px;
        color: #000;
        font-weight: bold;
    }
    
    .PageCurrent{
        font-size: 14px;
        color: #f00;
        font-weight: bold;
        border-bottom: solid 2px #f00;
    }
    
}
