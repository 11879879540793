.DetailContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/Nike-Dunk-SB-Street-Hawker-scaled.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* border: thin dotted #f00; */
}


@media (min-width: 1400px){

    .CardDetail{
        width: 55%;
        max-width: 60%;
        min-height: 530px;
        margin-top: 10%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
        /* border: thin dotted #f00; */
    }
    
    .ShoesImg{
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
        /* border: thin dotted #f00; */
    }
    
    .ShoesDetail{ 
        width: 55%;
        height: 100%;
        /* border: thin dotted #f00; */
    }
    
    .ImgDetail{
        width: 100%;
        /* border: thin dotted #00f; */
    
    }
    
    .Back{
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 24px;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .Brand{
        color: #00000050;
    }
    
    .PriceShoe{
        margin: 10px;
        font-size: 30px;
    }
    
    .Talles{
        margin: 10px 0;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 70%;
        height: 70px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        /* border: thin dotted #00f; */
    }
    
    .Talle{
        width: 32px;
        height: 17px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        padding: 0 5px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 12px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        font-size: 14px;
        text-align:justify;
        /* border: thin dotted #00f; */
    }
    
    .BtnCart{
        width: 50px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* border: thin dotted #0ff; */
    }

}

@media (min-width: 1200px) and (max-width: 1399px){

    .CardDetail{
        width: 70%;
        max-width: 70%;
        height: 450px;
        min-height: 450px;
        margin-top: 7%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
    }
    
    .ShoesImg{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .ShoesDetail{ 
        width: 65%;
        height: 100%;
    }
    
    .ImgDetail{
        width: 100%;
    }
    
    .Back{
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 1.5vw;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .NameShoe h1{
        font-size: 2.5vw;
    }
    
    .Brand h4{
        font-size: 1.5vw;
        color: #00000050;
        margin-top: -5px;
    }
    
    .PriceShoe{
        font-size: 30px;
        margin-top: 5px;
    }
    
    .Talles{
        margin: 5px 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 90%;
        height: 40px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
    }
    
    .Talle{
        width: 25px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        padding: 0 1px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 9px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        font-size: 11px;
        text-align:justify;
        margin-top: 5px;
    }
    
    .BtnCart{
        width: 50%;
        height: 25px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        /* border: thin dotted #00f; */
    }
    
}


@media (min-width: 992px) and (max-width: 1199px){
    
    .CardDetail{
        width: 85%;
        max-width: 85%;
        height: 450px;
        min-height: 450px;
        margin-top: 7%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
    }
    
    .ShoesImg{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .ShoesDetail{ 
        width: 65%;
        height: 100%;
    }
    
    .ImgDetail{
        width: 100%;
    }
    
    .Back{
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 2vw;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .NameShoe h1{
        font-size: 2.5vw;
    }
    
    .Brand h4{
        font-size: 1.5vw;
        color: #00000050;
        margin-top: -5px;
    }
    
    .PriceShoe{
        font-size: 30px;
        margin-top: 5px;
    }
    
    .Talles{
        margin: 5px 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 90%;
        height: 40px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
    }
    
    .Talle{
        width: 25px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        padding: 0 1px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 9px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        font-size: 11px;
        text-align:justify;
        margin-top: 5px;
    }
    
    .BtnCart{
        width: 50%;
        height: 25px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        /* border: thin dotted #00f; */
    }

}


@media (min-width: 768px) and (max-width: 991px){
    
    .CardDetail{
        width: 85%;
        max-width: 85%;
        height: 470px;
        min-height: 450px;
        margin-top: 7%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
    }
    
    .ShoesImg{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .ShoesDetail{ 
        width: 65%;
        height: 100%;
    }
    
    .ImgDetail{
        width: 100%;
    }
    
    .Back{
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 2.5vw;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .NameShoe h1{
        font-size: 3.5vw;
    }
    
    .Brand h4{
        font-size: 2vw;
        color: #00000050;
        margin-top: -5px;
    }
    
    .PriceShoe{
        font-size: 30px;
        margin-top: 5px;
    }
    
    .Talles{
        margin: 5px 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 77%;
        height: 67px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .Talle{
        width: 25px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        padding: 0 1px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 9px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        font-size: 11px;
        text-align:justify;
        margin-top: 5px;
    }
    
    .BtnCart{
        width: 50%;
        height: 25px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        /* border: thin dotted #00f; */
    }
    
}

@media (min-width: 576px) and (max-width: 767px){
    
    .CardDetail{
        width: 85%;
        max-width: 85%;
        height: 630px;
        min-height: 550px;
        margin-top: 7%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
    }
    
    .ShoesImg{
        width: 100%;
        height: 150px;
        margin-bottom: -30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .ImgDetail{
        width: 45%;
        margin-bottom: -30px;
    }

    .ShoesDetail{ 
        width: 100%;
        height: auto;
        min-height: 450px;
    }
    
    .Back{
        position: relative;
        top: -115px;
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 3.5vw;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .NameShoe h1{
        font-size: 5vw;
    }
    
    .Brand h4{
        font-size: 3vw;
        color: #00000050;
        margin-top: -5px;
    }
    
    .PriceShoe{
        font-size: 30px;
        margin-top: 5px;
    }
    
    .Talles{
        margin: 5px 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 90%;
        height: 70px;
        min-height: 67px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .Talle{
        width: 25px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        padding: 0 1px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 9px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        font-size: 11px;
        text-align:justify;
        margin-top: 5px;
    }
    
    .BtnCart{
        width: 50%;
        height: 25px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        /* border: thin dotted #00f; */
    }
    
}


@media (max-width: 575px){
    
    .CardDetail{
        width: 85%;
        max-width: 85%;
        height: 640px;
        min-height: 620px;
        margin-top: 7%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px 10px 50px 10px;
        box-shadow: 0 5px 10px rgba(0,0,0, 0.15);
    }
    
    .ShoesImg{
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .ImgDetail{
        width: 60%;
        margin-top: 20px;
    }

    .ShoesDetail{ 
        width: 100%;
        height: auto;
        min-height: 500px;
        margin-top: -60px;
    }
    
    .Back{
        position: relative;
        top: -115px;
        width: 100%;
        height: 30px;
        text-align: right;
    }
    
    .Back a{
        text-decoration: none;
        font-weight: bold;
        font-size: 5vw;
        color: #000;
        transition : 0.5s all;
    }
    
    .Back a:hover{
        color: #00000050;
        cursor: pointer;
    }
    
    .NameShoe h1{
        font-size: 5vw;
    }
    
    .Brand h4{
        font-size: 4vw;
        color: #00000050;
        margin-top: -5px;
    }
    
    .PriceShoe h3{
        font-size: 30px;
        margin-top: 5px;
    }
    
    .Talles{
        margin: 5px 0;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .TallesContainer{
        width: 90%;
        height: 95px;
        min-height: 60px;
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #00000030;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    
    .Talle{
        width: 25px;
        height: 14px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        padding: 0 1px;
        border-radius: 5px;
        float: left;
        margin-bottom: 5px;
        background-color: #000;
    }
    
    .Stock{
        height: 25px;
        margin-bottom: 5px;
        font-size: 9px;
        float: left;
        padding: 0 7px;
    }
    
    .Description{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    .Description p{
        width: 100%;
        font-size: 11px;
        text-align:justify;
        margin-top: 5px;
    }
    
    .BtnCart{
        width: 100%;
        height: 25px;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        /* border: thin dotted #00f; */
    }

}