.boxes {
    display: flex;
    justify-content: space-around;
    width: 96.3%;
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    
}
.box {
    width: 25%;
    text-align: left;
    box-shadow: 2px 2px 7px 0.5px #555;
    border-radius: 5px;
    height: 110px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    border-right: 5px solid red;
}
.title {
    font-size: 20px;
    margin: 0 0 4px 6px;
    color: #555;

}
.div {
    display: flex;
    align-items: center;
    height: 30px;
    margin: 0 0 6px 8px;

    
}
.number {
    font-size: 15px;
    color: #555;

    /* color: rgb(187, 186, 186); */
    font-weight: 600;
    letter-spacing: 3px;
}
.div span {
    color: rgb(79, 216, 79);
    font-size: 14px;
    margin-left: 10px;

}
.text {
    color: #555;
    font-size: 13px;
    margin: 0 0 0px 6px;


}