.LocationContainer{
    width: 100vw;
    height: 650px;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/bgJordan.jpg');
    background-size: cover;
    /* background: #ccc; */
    /* border: thin dotted #f00; */
}

.SubLocation{
   width: 80%;
   margin-top: 10px;
   margin: 0 auto;
   /* border: thin dotted #f00;  */
} 

.Location{
    width: 50%; 
    height: 600px;
    float: left;
    margin-right: 30px;
    overflow: hidden;
    background: #00000030;
}

.Form{
    width: 40%; 
    height: 600px;
    float: left;
    /* border: thin dotted #f00; */
}

.TitleLocation{
    font-size: 30px;
    font-weight: bold;
}

.SubTitleLocation{
    font-size: 20px;
}

.InputFormContact{
    width: 80%;
    height: 30px;
    padding: 10px;
    margin-left: 11px;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.InputFormContact:focus{
    outline:  solid 1px #f00;
}

.TextAreaFormContact{
    width: 80%;
    height: 150px;
    margin-left: 11px;
    padding: 10px;
    resize: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    /* border: thin dotted #f00; */
}

.TextAreaFormContact:focus{
    outline: solid 1px #f00;
}

.ButtonFormContact{
    width: 120px;
    height: 35px; 
    margin-top: 30px;
    border: none;
    background: #000000;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s all;
}

.ButtonFormContact:hover{
    cursor: pointer;
    /* background: #cf0000; */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
}

@media (min-width: 1200px) and (max-width: 1399px){

    .SubLocation{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
        /* border: thin dotted #00f; */
    }

    .Location{
        width: 50%; 
        max-height: 80%;
        float: left;
        margin-right: 30px;
        overflow: hidden;
        background: #00000030;
    }

    .Form{
        width: 50%; 
        height: 600px;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .Form form{
        min-width: 40vw;
        max-width: 40vw;
        position: relative;
        margin-top: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .TitleLocation{
        font-size: 2.2vw;
        font-weight: bold;
    }
    
    .SubTitleLocation{
        width: 80%;
        margin: 0 auto;
        font-size: 1.2vw;
    }


    .InputFormContact{
        width: 70%;
        height: 30px;
        padding: 10px;
        margin-left: 11px;
        border: none;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 1vw;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }
    
    .TextAreaFormContact{
        width: 70%;
        height: 150px;
        margin-left: 11px;
        font-size: 1vw;
        padding: 10px;
        resize: none;
        border: none;
        border-radius: 5px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }
}


@media (min-width: 992px) and (max-width: 1199px){
    
    .LocationContainer{
        width: 100vw;
        height: 600px;
        margin: 3vh 0 3vh 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/bgJordan.jpg');
        background-size: cover;
        /* border: thin dotted #f00; */
    }
    
    .SubLocation{
        width: 95vw;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
        /* border: thin dotted #00f; */
    }
    
    .Location{
        width: 50vw;
        max-height: 70%;
    }
    
    .TitleLocation{
        font-size: 3vw;
        font-weight: bold;
    }
    
    .SubTitleLocation{
        width: 45vw;
        font-size: 1.5vw;
    }
    
    .Form{
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .Form form{
        width: 50vw;
        position: relative;
        margin-top: -2vh;
        font-size: 1.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .TextAreaFormContact{
        height: 12vh;
    }
    
}

@media (min-width: 768px) and (max-width: 991px){

    .LocationContainer{
        width: 100vw;
        height: 550px;
        margin: 3vh 0 3vh 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/bgJordan.jpg');
        background-size: cover;
    }
    
    .SubLocation{
        width: 95%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
    }
    
    .Location{
        width: 50%;
        max-height: 500px;
    }
    
    .TitleLocation{
        font-size: 1.7rem;
        line-height: 22px;
        font-weight: bold;
    }
    
    .SubTitleLocation{
        width: 100%;
        font-size: 1rem;
    }
    
    .Form{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .Form form{
        width: 450px;
        position: relative;
        margin-top: -15%;
        font-size: 1.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .TextAreaFormContact{
        height: 120px;
    }
    
    
}

@media (min-width: 576px) and (max-width: 767px){
    
    .LocationContainer{
        width: 100vw;
        min-height: 950px;
        margin: 3vh 0 3vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/bgJordan.jpg');
        background-size: cover;
        /* border: thin dotted #000; */
        /* background: #ccc; */
    }
    
    .SubLocation{
        width: 100vw;
        overflow: hidden;
    }
    
    .Location{
        width: 100vw;
        height: 350px;
    }
    
    .Form{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .TitleLocation{
        font-size: 32px;
        font-weight: bold;
    }
    
    .SubTitleLocation{
        width: 70vw;
        margin: 0 auto;
        font-size: 2.5vw;
    }
    
    .Form form{
        width: 88vw;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .TextAreaFormContact{
        height: 150px;
    }
   
}

@media (max-width: 575px){

    .LocationContainer{
        width: 100vw;
        min-height: 800px;
        height: 830px;
        margin: 5vh 0 5vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/bgJordan.jpg');
        background-size: cover;
    }
    
    .SubLocation{
        width: 100vw;
    }
    
    .Location{
        width: 100vw;
        height: 35vh;
    }

    .Form{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .TitleLocation{
        width: 90vw;
        font-size: 6vw;
        font-weight: bold;
    }
    
    .SubTitleLocation{
        width: 90vw;
        font-size: 3.5vw;
    }
    
    .Form form{
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 3vw;
        position: relative;
        top: -4vh;
    }
}
