.boxModalOrder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: auto;
    height: auto;
    box-shadow: 0px 5px 15px rgba(0, 0 , 0, 0.90);
    border-radius: 7px;
    width: 300px;
    height: 200px;
    padding: 5px;
}
.boxContentOrder {
    width:100%;
    border: 1px solid black;
    height: auto;
}

