.NavContainer{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* border: thin dotted #f00; */
}

.LogoContainer{
    width: 20vw;
    height: 50px;
    float: left;
    /* border: thin dotted #f00; */
}

.LogoNav{
    width: 150px;
}

.LogoNav:hover{ 
    cursor: pointer;
}

.MenuContainer{
    width: 60vw;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* border: thin dotted #f00; */
}

ul li{
    list-style: none;
    transition: 0.1s all;
    font-size: 1.1em;
    /* border: thin dotted #f00; */
}

ul li a{
    text-decoration: none;
    color: #000;
}

ul li:hover, ul li a:hover{
    color: #f00;
    cursor: pointer;
    /* transform: translateY(-3px); */
}

.Link{
    color: #000;
}

.Menu{
    width: 110px;
    float: left;
    padding: 5px
}

.ToolsContainer{
    width: 20vw;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    /* border: thin dotted #f00; */
}

.Tool{
    width: 50px;
    float: left;
    /* border: thin dotted #f00; */
}

.Icon{
    transition: 0.5s all;
}

.Icon:hover{
    color: #f00;
    cursor: pointer;
}

.FormSearch{
    position: relative;
    top: -3px;
    left: 80%; 
    transform: translate(-100%,0%);
    transition: all 1s;
    width: 30px;
    height: 30px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 4px;
    border: thin solid #fff;
}

.SearchBar{
    width: 200px;
    height: 30px;
    line-height: 30px;
    outline: 0;
    border: none;
    background: transparent;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 5px;
}

.fa{
    box-sizing: border-box;
    padding: 5px;
    width: 30x;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

.FormSearch:hover{
    width: 250px;
    cursor: pointer;
}

.FormSearch:hover .SearchBar{
    display: block;
}


.FormSearch:hover .fa{
    background: #07051a;
    color: white;
}

.ToolTipsUser{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LinkUserContainer{
    width: 100%;
    height: 30px;
    padding:0 5px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: thin dotted #f00; */
}

.LinkUserContainer a{
    font-size: 18px;
    padding: 5px;
    text-decoration: none;
    color: #fff;
    transition: 0.5s all;
}

.LinkUserContainer a:hover{
    text-shadow: 0 5px 8px #000000;
}




.LinkMarcaContainer1 a:hover, .LinkMarcaContainer2 a:hover, .LinkMarcaContainer3 a:hover, .LinkCategoriaContainer1 a:hover{
    color: #000000;
}



@media (min-width: 1400px) {
    
    .NavContainerMobile{
        display: none;
    }
    
    .LinkCategoriaContainer1 a{
        width: 90px;
        font-size: 16px;
        padding: 5px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: center;
    }
    
    .LinkMarcaContainer1 a{
        width: 75px;
        font-size: 16px;
        padding: 3px 0;
        margin-right: 5px;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: left;
        /* border: thin dotted #0f0; */
    }

    .LinkMarcaContainer2 a{
        width: 90px;
        font-size: 16px;
        padding: 3px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: center;
        /* border: thin dotted #f00; */
    }
    
    .LinkMarcaContainer3 a{
        width: 70px;
        font-size: 16px;
        padding: 3px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: right;
        /* border: thin dotted #00f; */
    }

}

@media (min-width: 1200px) and (max-width: 1399px) {

    .NavContainerMobile{
        display: none;
    }

    .Logo{
        width: 120px;
    }

    .Menu{
        font-size: 14px;
        width: 80px;
        float: left;
        padding: 5px;
    }

    .fa{
        font-size: 18px;
    }

    .FormSearch{
        position: relative;
        top: -3px;
        left: 90%; 
        transform: translate(-100%,0%);
        transition: all 1s;
        width: 30px;
        height: 30px;
        background: white;
        box-sizing: border-box;
        border-radius: 30px;
        border: 4px solid white;
        padding: 4px;
        border: solid 1px #fff;
    }

    .FormSearch:hover{
        width: 200px;
        cursor: pointer;
    }

    .SearchBar{
        width: 160px;
        height: 30px;
        line-height: 30px;
        outline: 0;
        border: solid 1px #ccc;
        background: transparent;
        display: none;
        border-radius: 20px;
        padding: 5px;
        font-size: 12px;
    }

    .LinkCategoriaContainer1 a{
        width: 90px;
        font-size: 1.2vw;
        padding: 5px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: center;
    }
    
    .LinkMarcaContainer1 a{
        width: 75px;
        font-size: 1.2vw;
        padding: 3px 0;
        margin-right: 5px;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: left;
        /* border: thin dotted #0f0; */
    }

    .LinkMarcaContainer2 a{
        width: 90px;
        font-size: 1.2vw;
        padding: 3px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: center;
        /* border: thin dotted #f00; */
    }
    
    .LinkMarcaContainer3 a{
        width: 70px;
        font-size: 1.2vw;
        padding: 3px 0;
        text-decoration: none;
        color: #00000070;
        float: left;
        transition: 0.5s all;
        text-align: right;
        /* border: thin dotted #00f; */
    }
    
}

@media (min-width: 992px) and (max-width: 1199px){

    .NavContainer{
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .MenuContainer, .ToolsContainer, .LogoContainer{
        display: none;
    }

    .NavContainerMobile{
        width: 90vw;
        margin-top: 2.5vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* border: thin dotted #f00; */
    } 

    .LogoNavMobile{
        width: 90vw;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: flex-start;
        z-index: 11;
        /* border: thin dotted #0f0; */
    }
    
    .NavContainerMobile img{
        /* width: 35vw; */
        width: 20vw;
        /* border: thin dotted #f00; */
    }

    .MenuNavMobile{
        width: 10vw;
        display: flex;
        flex-direction: row;
        align-items: right;
        justify-content: space-between;
        /* border: thin dotted #00f; */
    }
    
    .NavigationMobile{
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 100;
        transition: all 0.5s ease;
    }

    .NavigationMobile.active{
        background-color: #fff;
        left: 0;
    }

    .NavigationMobile .LinkMobile{
        width: 100px;
        height: 30px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        /* border: 1px solid #ccc; */
        border-radius: 15px;
    }

    .NavigationMobile .LinkMobile:hover{
        background-color: #00000040;
        color: #fff;
    }

    .MenuNavMobile .IconMen:hover{
        cursor: pointer;
        color: #f00;
    }

    .MenuNavMobile .IconMen:nth-child(2){
        display: none;
    }

    .MenuNavMobile.active .IconMen:nth-child(2){
        display: block;
        z-index: 130;
    }

    .MenuNavMobile.active .IconMen:nth-child(1){
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px){

    .NavContainer{
        height: 120px;
        max-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .MenuContainer, .ToolsContainer, .LogoContainer{
        display: none;
    }

    .NavContainerMobile{
        width: 90%;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* border: thin dotted #f00; */
    } 

    .LogoNavMobile{
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: flex-start;
        z-index: 11;
        /* border: thin dotted #0f0; */
    }
    
    .NavContainerMobile img{
        /* width: 35vw; */
        width: 150px;
        /* border: thin dotted #f00; */
    }

    .MenuNavMobile{
        width: 10%;
        display: flex;
        flex-direction: row;
        align-items: right;
        justify-content: space-between;
        /* border: thin dotted #00f; */
    }
    
    .NavigationMobile{
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 100;
        transition: all 0.5s ease;
    }

    .NavigationMobile.active{
        background-color: #fff;
        left: 0;
    }

    .NavigationMobile .LinkMobile{
        width: 100px;
        height: 30px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        /* border: 1px solid #ccc; */
        border-radius: 15px;
    }

    .NavigationMobile .LinkMobile:hover{
        background-color: #00000040;
        color: #fff;
    }

    .MenuNavMobile .IconMen:hover{
        cursor: pointer;
        color: #f00;
    }

    .MenuNavMobile .IconMen:nth-child(2){
        display: none;
    }

    .MenuNavMobile.active .IconMen:nth-child(2){
        display: block;
        z-index: 130;
    }

    .MenuNavMobile.active .IconMen:nth-child(1){
        display: none;
    }
    
}

@media (min-width: 576px) and (max-width:767px){

    .NavContainer{
        height: 120px;
        max-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .MenuContainer, .ToolsContainer, .LogoContainer{
        display: none;
    }

    .NavContainerMobile{
        width: 90%;
        margin-top: 2.5vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* border: thin dotted #f00; */
    } 

    .LogoNavMobile{
        width: 90vw;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: flex-start;
        z-index: 11;
        /* border: thin dotted #0f0; */
    }
    
    .NavContainerMobile img{
        /* width: 35vw; */
        width: 25vw;
        /* border: thin dotted #f00; */
    }

    .MenuNavMobile{
        width: 10vw;
        display: flex;
        flex-direction: row;
        align-items: right;
        justify-content: space-between;
        /* border: thin dotted #00f; */
    }
    
    .NavigationMobile{
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 100;
        transition: all 0.5s ease;
    }

    .NavigationMobile.active{
        background-color: #fff;
        left: 0;
    }

    .NavigationMobile .LinkMobile{
        width: 100px;
        height: 30px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        /* border: 1px solid #ccc; */
        border-radius: 15px;
    }

    .NavigationMobile .LinkMobile:hover{
        background-color: #00000040;
        color: #fff;
    }

    .MenuNavMobile .IconMen:hover{
        cursor: pointer;
        color: #f00;
    }

    .MenuNavMobile .IconMen:nth-child(2){
        display: none;
    }

    .MenuNavMobile.active .IconMen:nth-child(2){
        display: block;
        z-index: 130;
    }

    .MenuNavMobile.active .IconMen:nth-child(1){
        display: none;
    }
    
}

@media (max-width: 575px){

    .NavContainer{
        height: 70px;
        max-height: 100px;
        margin-bottom: 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .MenuContainer, .ToolsContainer, .LogoContainer{
        display: none;
    }

    .NavContainerMobile{
        width: 90vw;
        margin-top: 2.5vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* border: thin dotted #f00; */
    } 
    
    .LogoNavMobile{
        width: 90vw;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: flex-start;
        z-index: 11;
        /* border: thin dotted #0f0; */
    }
    
    .NavContainerMobile img{
        /* width: 35vw; */
        width: 25vw;
        /* border: thin dotted #f00; */
    }
    
    .MenuNavMobile{
        width: 10vw;
        display: flex;
        flex-direction: row;
        align-items: right;
        justify-content: space-between;
        /* border: thin dotted #00f; */
    }
    
    .NavigationMobile{
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: -100vw;
        z-index: 100;
        overflow-y: hidden;
        transition: all 0.5s ease;
    }
    
    .NavigationMobile li{
        /* border: thin dotted #00f; */
        margin-left: -10%;

    }

    .NavigationMobile.active{
        background-color: #fff;
        overflow-y: hidden;
        left: 0;
    }

    .NavigationMobile .LinkMobile{
        width: 100px;
        height: 30px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        /* border: 1px solid #ccc; */
        border-radius: 15px;
    }
    
    .NavigationMobile .LinkMobile:hover{
        background-color: #00000040;
        color: #fff;
    }
    
    .MenuNavMobile .IconMen:hover{
        cursor: pointer;
        color: #f00;
    }
    
    .MenuNavMobile .IconMen:nth-child(2){
        display: none;
    }
    
    .MenuNavMobile.active .IconMen:nth-child(2){
        display: block;
        z-index: 130;
    }
    
    .MenuNavMobile.active .IconMen:nth-child(1){
        display: none;
    }
    
    .LinkNavBarMobile{
        width: 90px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        float: left;
        /* border: 1px solid #ccc; */
    }

    .LinkNavBarMobile a{
        color: #000;
        border-radius: 15px;
        width: 80px;
        height: 25px;
        text-decoration: none;
        transition: all 0.5s;
    }

    .LinkNavBarMobile a:hover{
        background-color: #00000040;
        color: #fff;
        cursor: pointer;
    }
    
    .AccordMarcas{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-weight: lighter;
        /* border: 1px solid #ccc; */
    }
}