.chart {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
}
.chartTitle {
    margin-bottom: 20px;
    text-align: left;
    color: #555;
    
}
.graphic {
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0 , 0, 0.75);
    margin: 20px;
}